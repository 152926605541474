<template>
  <div class="field col-12 md:col-4 lg:col-2">
    <label> Nome:</label> <br />
    <input type="text" v-model="aluno.nome" class="p-inputtext p-component" />
  </div>
  <div class="field col-12 md:col-4 lg:col-2">
    <label> Sobrenome:</label> <br />
    <input
      type="text"
      v-model="aluno.sobrenome"
      class="p-inputtext p-component"
    />
  </div>
  <div class="field col-12 md:col-3 lg:col-3" style="margin-top: 31px">
    <button
      v-if="aguardeLoading == false"
      class="btn btn-primary"
      @click="buscarAluno()"
    >
      Pesquisar
    </button>

    <button disabled v-if="aguardeLoading == true" class="btn btn-primary">
      Aguarde...
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  components: {},
  props: {},
  data() {
    return {
      aguardeLoading: false,
      aluno: {
        nome: "",
        sobrenome: "",
      },
    };
  },
  methods: {
    buscarAluno() {
      this.$emit("buscarAluno", this.aluno);
    },
  },
});
</script>
